import React, {
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { Icon } from 'kinedu-react-components';
import KineduLogo from '../KineduLogo/KineduLogo';
import Button from 'components/extended/Button';
import { Link } from 'gatsby';
import { PROJECT_PATH, windowGlobal } from '../../../config';

const Navbar = styled.nav.attrs(() => ({
  className: 'animate__animated',
}))`
  display: flex;
  padding: 10px;
  transition: all 0.2s ease-in;
  justify-content: space-between;
  background-color: transparent;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  /* height: 132px; */
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1060px;
  margin: 0 auto;
  width: 100%;
`;

const MenuSection = styled.div`
  display: flex;
  align-items: flex-end;
`;

const LanguageButton = styled.a`
  height: 28px;
  width: 28px;
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  box-sizing: border-box;
  border-radius: 100%;
  line-height: 28px;
  border: 1px solid ${({ theme }) => theme?.colors?.primary};
  color: ${({ theme }) => theme?.colors?.primary};
  background-color: #fff;
  ${({ selected, theme }) => selected ? `
    background-color: ${theme?.colors?.primary};
    color: #fff;
    font-weight: 600;
  ` : ''}
  ${({ theme }) => `
    ${theme.breakPoints.tablet} {
      width: 28px;
      height: 28px;
      font-size: 14px;
      line-height: 35px;
    }
    ${theme.breakPoints.tablet} {
      width: 36px;
      height: 36px;
      font-size: 14px;
      line-height: 35px;
    }
  `}
`;

const Logo = styled(KineduLogo)`
  width: 153px;
  ${({ theme }) => `
    ${theme.breakPoints.tablet} {
      width: 320px;
    }
  `}
`;

const LanguageSelector = styled.div`
  display: flex;
  align-items: center;
  ${LanguageButton} {
    margin: 5px;
    &:focus {
      outline: none;
    }
  }
`;

const MenuButton = styled(Button)`
  padding: 5px;
  height: 30px;
  width: 30px;
  min-height: auto;
  ${({ theme, marginRight }) => `
    ${marginRight ? 'margin-right: 10px;' : ''}
    ${theme.breakPoints.xLargePhone} {
      display: none;
    }
  `}
`;

const SideNav = styled.div`
  position: fixed;
  transition: all 0.3s ease-in-out;
  left: -340px;
  z-index: 10;
  width: 340px;
  top: 0;
  background-color: #fff;
  box-shadow: 2px 2px 4px 0 rgba(173,173,173,0.50);
  height: 100%;

  ${({ opened }) => opened ? `
    transform: translateX(340px);
  ` : ''}
`;

const Overlay = styled.div`
  transition: all 500ms ease-in-out;
  background-color: rgba(0,0,0,0.3);
  position: fixed;
  z-index: 9;
  width: 100%;
  height: 100%;
  top: 0;
  ${({ visible }) => visible ? 'display: block;' : 'display: none;'}
`;

const SideNavTitle = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 1.9rem;
  color: #4c4c4c;
`;

const SideNavHeader = styled.div`
  padding: 10px 20px;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E8E8E8;
`;

const SideNavContent = styled.div`
  background-color: #fff;
  height: 100vh;
`;

const SideNavList = styled.ul`
  padding: 0 20px;
  list-style: none;
`;

const SideNavListHeader = styled.li`
  font-weight: 600;
  margin: 10px 0 10px 0px;
  font-size: 2rem;
  a {
    color: ${({ theme }) => theme?.colors?.primary};
  }
`;

const SideNavListItem = styled.li`
  font-size: 1.5rem;
  color: #4c4c4c;
  padding-left: 1rem;
  margin: 0;
  font-weight: 300;
  padding: 5px 10px;

`;

const MenuList = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;
`;

const Menu = styled.ul`
  display: flex;
  align-items: center;
  padding: 0;
  list-style: none;
  margin: 0;
  display: none;
  ${({ theme }) => `
    ${theme.breakPoints.xLargePhone} {
      display: flex;
    }
  `}
  `;

const MenuItem = styled.li`
  margin: 0;
  padding: 10px 20px;
  color: #4C4C4C;
  font-weight: 600;
`;

const NavbarComponent = ({
  lang = 'en',
}) => {
  const navbarRef = useRef();

  const themeContext = useContext(ThemeContext);

  const [isOpened, setIsOpened] = useState(false);

  const handleOnOpenMenu = () => {
    setIsOpened(!isOpened);

    if (isOpened) {
      document.body.style.overflow = 'visible';
    } else {
      document.body.style.overflow = 'hidden';
    }
  };

  const addBackgroundToNavbar = () => {
    navbarRef.current.classList.add('animate__slideInDown', 'white-navbar');
  };

  const removeBackgroundToNavbar = () => {
    navbarRef.current.classList.remove('animate__slideInDown', 'white-navbar');
  };

  const checkNavbarColor = () => {
    const isAtTop = windowGlobal?.scrollY < 1;

    if (isAtTop) {
      removeBackgroundToNavbar();
    } else {
      addBackgroundToNavbar();
    }
  };

  useEffect(() => {
    if (navbarRef.current) {
      windowGlobal.addEventListener('scroll', checkNavbarColor);
    }
    return () => windowGlobal.removeEventListener('scroll', checkNavbarColor);
  }, []);

  return (
    <Navbar ref={navbarRef}>
      <NavbarContainer>
        <MenuSection>
          {/* <MenuButton marginRight onClick={handleOnOpenMenu}>
            <Icon size={20} name="menu" color={themeContext.colors.primary} />
          </MenuButton> */}
          <Logo lang={lang} />
        </MenuSection>
        <MenuList>
          {/* <Menu>
            Hidden temporarily
            <MenuItem>
              <Link to="/features/calendar"> Features </Link>
            </MenuItem>

            <MenuItem>
              <Link to="/pricing/"> Pricing </Link>
            </MenuItem>

            <MenuItem>
              <Link to="/for-parents/"> For Parents </Link>
            </MenuItem>
          </Menu> */}
          <LanguageSelector>
            <LanguageButton href={`${PROJECT_PATH()}/es`} selected={lang === 'es'}> ES </LanguageButton>
            <LanguageButton href={`${PROJECT_PATH()}/`} selected={lang === 'en'}> EN </LanguageButton>
            <LanguageButton href={`${PROJECT_PATH()}/pt`} selected={lang === 'pt'}> PT </LanguageButton>
          </LanguageSelector>
        </MenuList>
        <SideNav opened={isOpened}>
          <SideNavHeader>
            <SideNavTitle> Menu </SideNavTitle>
            <MenuButton onClick={handleOnOpenMenu}>
              <Icon size={20} name="menu" color={themeContext.colors.primary} />
            </MenuButton>
          </SideNavHeader>
          <SideNavContent>
            <SideNavList>
              <SideNavListHeader>
                <Link to="/features">
                  Features
                </Link>
              </SideNavListHeader>
              <SideNavListItem>
                <Link to="/features/calendar">
                  Calendar
                </Link>
              </SideNavListItem>
              <SideNavListItem>
                <Link to="/features/messages">
                  Messages
                </Link>
              </SideNavListItem>
              <SideNavListItem>
                <Link to="/features/messages">
                  Resources
                </Link>
              </SideNavListItem>
              <SideNavListItem>
                <Link to="/features/catalog">
                  Catalog
                </Link>
              </SideNavListItem>
              <SideNavListItem>
                <Link to="/features/activity-plan">
                  Activity Plan
                </Link>
              </SideNavListItem>
              <SideNavListHeader>
                <Link to="/pricing">
                  Pricing
                </Link>
              </SideNavListHeader>
              <SideNavListHeader>
                <Link to="/for-parents">
                  For Parents
                </Link>
              </SideNavListHeader>
            </SideNavList>
          </SideNavContent>
        </SideNav>
      </NavbarContainer>
      <Overlay visible={isOpened} onClick={handleOnOpenMenu} />
    </Navbar>
  );
};

NavbarComponent.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default NavbarComponent;
