import React from "react"
import styled, { ThemeProvider } from "styled-components"
import Layout from "components/LandingLayout"
import metaTranslations from "../lib/utils"
import SEO from "components/seo"
import theme from "../themes"
import NavbarComponent from "../components/Landing/Navbar/Navbar"
import { windowGlobal } from "../config"
import { Button, Switcher } from "kinedu-react-components"
import PlusIcon from "../images/landing/plus_icon.svg"
import CloseIcon from "../images/landing/close_icon.svg"
import ComingSoon from "../images/landing/coming_soon.svg"
import { WithTrans } from "../language/withTrans"

const MainContainer = styled.div`
  background-color: #3eb646;
  height: 360px;
  width: 100%;
  margin: 0 auto;

  ${({ theme }) => `
  ${theme.breakPoints.tablet} {
    width: 89%;
  }
`}
`;

const TitleContainer = styled.div`
  text-align: center;
  padding-top: 54px;
  padding-bottom: 39px;
  display: none;

  ${({ theme }) => `
  ${theme.breakPoints.tablet} {
    display: block;
  }
`}
`

const Title = styled.p`
  margin: 0;
  font-weight: bold;
  color: #ffffff;
  font-size: 28px;
`

const PriceTable = styled.div`
  width: 100%;
  height: 1067px;
  background-color: #ffffff;
  margin: 0 auto;
  border-radius: 0px;
  padding-top: 32px;

  ${({ theme }) => `
  ${theme.breakPoints.tablet} {
    width: 83%;
    border-radius: 10px;
    padding-top: 62px;
  }
`}
`

const PlanName = styled.p`
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin: 0;

  ${({ theme }) => `
  ${theme.breakPoints.xLargePhone} {
    font-size: 32px;
  }
`}
`

const PlanPrice = styled.p`
  color: #0086d8;
  font-size: 20px;
  font-weight: bold;
  text-align: center;

  ${({ theme }) => `
  ${theme.breakPoints.xLargePhone} {
    font-size: 40px;
  }
`}
`

const ContactUs = styled.p`
  color: #0086d8;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin: 0;
  ${({ theme }) => `
  ${theme.breakPoints.xLargePhone} {
    font-size: 28px;
  }
`}
`

const PlanDescription = styled.p`
  font-weight: bold;
  font-size: 11px;
  color: #a3a3a3;
  ${({ theme }) => `
  ${theme.breakPoints.xLargePhone} {
    font-size: 14px;
  }
`}
`

const ButtonSelectPlan = styled(Button).attrs(() => ({
  rounded: true,
  flat: true,
}))`
  width: 88px;
  height: 40px;
  background-color: #73b84e;
  color: white;

  ${({ theme }) => `
  ${theme.breakPoints.xLargePhone} {
    width: 167px;
    height: 40px;
  }
`}
`

const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  ${({ theme }) => `
  ${theme.breakPoints.largeTablet} {
    flex-direction: row;
  }
  `}
`

const BilledType = styled.div`
  width: 100%;
  display: none;

  ${({ theme }) => `
  ${theme.breakPoints.largeTablet} {
    width: 35%;
    justify-content: center;
    display: flex;
  }
  `}
`

const Plans = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;

  ${({ theme }) => `
  ${theme.breakPoints.largeTablet} {
    width: 65%;
  }
  `}
`

const BenefitsTable = styled.div`
  margin-top: 20px;
`

const Section = styled.div`
  background-color: #f8f8f8;
  height: 60px;
  width: 100%;
`

const SectionTitle = styled.p`
  color: #333333;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  padding-top: 20px;
  padding-left: 21px;
  text-align: center;

  ${({ theme }) => `
  ${theme.breakPoints.xLargePhone} {
    font-size: 20px;
    text-align: left;
  }
`}
`

const Subsection = styled.div`
  width: 100%;
  border: 0;
  text-align: center;

  ${({ theme }) => `
  ${theme.breakPoints.xLargePhone} {
    text-align: left;
    border-bottom: 1px solid #E8E8E8;
  }
`}
`

const SubsectionTitle = styled.div`
  padding-left: 22px;
  color: #565656;
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 0px;
  ${({ theme }) => `
  ${theme.breakPoints.xLargePhone} {
    margin-bottom: 20px;
  }
`}
`

const Row = styled.div`
  display: flex;
  border-bottom: 1px solid #e8e8e8;
  height: auto;
  flex-direction: column;

  ${({ theme }) => `
  ${theme.breakPoints.xLargePhone} {
    height: 54px;
    flex-direction: row;
  }
`}
`

const ColumnSection = styled.div`
  width: 100%;
  text-align: center;
  ${({ theme }) => `
  ${theme.breakPoints.xLargePhone} {
    width: 36%;
    text-align: left;
  }
`}
`

const ColumnTitle = styled.p`
  padding-left: 21px;
  color: #929392;
  font-size: 14px;
  margin-top: 18px;
  margin-bottom: 18px;
`

const ColumnBenefits = styled.div`
  width: 100%;
  display: flex;

  ${({ theme }) => `
  ${theme.breakPoints.xLargePhone} {
    width: 64%;
  }
`}
`

const Image = styled.img`
  height: 24px;
  width: ${props => (props.isComingSoon ? "96px" : "")};

  ${({ theme }) => `
  ${theme.breakPoints.xLargePhone} {
    margin-top: 14px;
    width: 136px;
  }
`}
`

const ImageContainer = styled.div`
  width: 33.33%;
  margin-bottom: 15px;
  border-right: ${props => (!props.isLastElement ? "1px solid #E8E8E8" : "")};
  text-align: ${props => (props.isComingSoon ? "center" : "")};

  ${({ theme }) => `
    ${theme.breakPoints.xLargePhone} {
      border-right: 0;
      text-align: left;
    }
`}
`;

const BillyLabel = styled.p`
  color: ${props => (props.billyType === 'monthly' ? "#717171" : "#0086D8")};
  font-weight: ${props => (props.billyType === 'yearly' ? "bold" : "normal")};
  font-size: 16px;
  margin-bottom: ${props => (props.billyType === 'yearly' ? "11px" : "")};
`;

const SaveText = styled.p`
  font-size: 12px;
  color: #75B753;
  font-weight: bold;
  margin-top: 0px;
`;

const SwitchContainer = styled.div`
  margin-right: 21px;
  margin-left: 21px;
  margin-top: 10px;
`;

const ContactUsContainer = styled.div`
  height: 100%;
  margin-bottom: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => `
  ${theme.breakPoints.xLargePhone} {
    margin-bottom: 30px;
  }
`}
`;

const EnterpriseContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TABLE_DATA = [
  {
    section: "Core features",
  },
  {
    title: "Personalized weekly learning plans",
    basic: true,
    plus: true,
    enterprise: true,
  },
  {
    title: "Activity plans for parents",
    basic: true,
    plus: true,
    enterprise: true,
  },
  {
    title: "Developmental assessments",
    basic: true,
    plus: true,
    enterprise: true,
  },
  {
    title: "Access 1,800+ activities",
    basic: true,
    plus: true,
    enterprise: true,
  },
  {
    section: "Features for parents",
  },
  {
    subsection: "Unlimited access to Kinedu app",
  },
  {
    title: "Personalized daily activity plan",
    basic: true,
    plus: true,
    enterprise: true,
  },
  {
    title: "Milestone trackers",
    basic: false,
    plus: true,
    enterprise: true,
  },
  {
    title: "Progress Charts",
    basic: false,
    plus: true,
    enterprise: true,
  },
  {
    subsection: "Classrooms section",
  },
  {
    title: "Feed and chat",
    basic: false,
    plus: "comingSoon",
    enterprise: "comingSoon",
  },
  {
    section: "Support",
  },
  {
    title: "Priority support",
    basic: false,
    plus: false,
    enterprise: true,
  },
]
const IS_COMING_SOON = "comingSoon";
const getIcon = status =>
  status === "comingSoon" ? ComingSoon : status ? PlusIcon : CloseIcon

const Pricing = ({ lang }) => {
  return (
    <Layout>
      <SEO
        title={metaTranslations[lang].title}
        description={metaTranslations[lang].description}
        meta={[
          {
            name: "og:image",
            content:
              "https://kinedu-media.s3.amazonaws.com/classrooms_by_kinedu_2.jpg",
          },
        ]}
      />
      <ThemeProvider theme={theme}>
        <NavbarComponent lang={"es"} />
        <div>
          <MainContainer>
            <TitleContainer>
              <Title>Chose the best plan for your school</Title>
            </TitleContainer>

            <PriceTable>
              <div>
                <PlanContainer>
                  <BilledType>
                    <BillyLabel billyType={'monthly'} >Billed monthly</BillyLabel>
                    <SwitchContainer>
                      <Switcher enabled={true} onChange={() => {}} />
                    </SwitchContainer>
                    <div>
                      <BillyLabel billyType={'yearly'}>Billed yearly</BillyLabel>
                      <SaveText>Save 33%</SaveText>
                    </div>
                  </BilledType>

                  <Plans>
                    <div>
                      <PlanName>Basic</PlanName>
                      <PlanPrice>$0</PlanPrice>
                      <PlanDescription>
                        USD per student / per year
                      </PlanDescription>
                      <ButtonSelectPlan color="#73B84E">
                        {" "}
                        Select{" "}
                      </ButtonSelectPlan>
                    </div>

                    <div>
                      <PlanName>Plus</PlanName>
                      <PlanPrice>$59.00</PlanPrice>
                      <PlanDescription>
                        USD per student / per year
                      </PlanDescription>
                      <ButtonSelectPlan color="#73B84E">
                        {" "}
                        Select{" "}
                      </ButtonSelectPlan>
                    </div>

                    <EnterpriseContainer>
                      <PlanName>Enterprise</PlanName>
                      <ContactUsContainer>
                        <ContactUs>Contact us</ContactUs>
                      </ContactUsContainer>

                      <ButtonSelectPlan color="#73B84E">
                        {" "}
                        Select{" "}
                      </ButtonSelectPlan>
                    </EnterpriseContainer>
                  </Plans>
                </PlanContainer>

                <BenefitsTable>
                  {TABLE_DATA.map(item => {
                    if (item.section) {
                      return (
                        <Section>
                          <SectionTitle>{item.section}</SectionTitle>
                        </Section>
                      )
                    } else if (item.subsection) {
                      return (
                        <Subsection>
                          <SubsectionTitle>{item.subsection}</SubsectionTitle>
                        </Subsection>
                      )
                    } else {
                      return (
                        <Row>
                          <ColumnSection>
                            <ColumnTitle>{item.title}</ColumnTitle>
                          </ColumnSection>

                          <ColumnBenefits>
                            <ImageContainer>
                              <Image src={getIcon(item.basic)} />
                            </ImageContainer>
                            <ImageContainer
                              isComingSoon={item.enterprise === IS_COMING_SOON}
                            >
                              <Image
                                isComingSoon={item.plus === IS_COMING_SOON}
                                src={getIcon(item.plus)}
                              />
                            </ImageContainer>
                            <ImageContainer
                              isComingSoon={item.enterprise === IS_COMING_SOON}
                              isLastElement={true}
                            >
                              <Image
                                isComingSoon={item.enterprise === IS_COMING_SOON}
                                src={getIcon(item.enterprise)}
                              />
                            </ImageContainer>
                          </ColumnBenefits>
                        </Row>
                      )
                    }
                  })}
                </BenefitsTable>
              </div>
            </PriceTable>
          </MainContainer>
        </div>
      </ThemeProvider>
    </Layout>
  );
};

export default WithTrans()(Pricing);
